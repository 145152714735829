import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

function Songs() {
  const [songs, setSongs] = useState([]);
  const [selectedType, setSelectedType] = useState("bachata");
  const [selectedYear, setSelectedYear] = useState("all");
  const [years, setYears] = useState(["all"]);
  const sortedSongs = [...songs].sort((a, b) => b.popularity - a.popularity);

  const types = [
    { key: "bachata", label: "Bachata" },
    { key: "salsa", label: "Salsa" },
    { key: "kizomba", label: "Kizomba" },
    { key: "zouk", label: "Zouk" },
    { key: "west cost swing", label: "West Coast Swing" },
  ];
  useEffect(() => {
    const fetchSongs = async () => {
      let url = `${process.env.REACT_APP_API_URL}/songs/`;
      if (selectedType !== "all") {
        url += `?playlist_type=${selectedType}`;
      }
      if (selectedYear !== "all") {
        url += (selectedType !== "all" ? "&" : "?") + `year=${selectedYear}`;
      }
      const response = await fetch(url);
      const data = await response.json();
      setSongs(data.results);
      setYears(["all", ...data.years]);
    };

    fetchSongs();
  }, [selectedType, selectedYear]);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Top Social Dancing Songs for parties
          </Typography>
          <Typography variant="body2" gutterBottom>
            Use the filters to explore songs by genre and year.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={selectedType}
              label="Type"
              onChange={(e) => setSelectedType(e.target.value)}
            >
              {types.map((type) => (
                <MenuItem key={type.key} value={type.key}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              label="Year"
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "5%",
                  fontWeight: "bold",
                }}
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  width: "60%",
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  width: "40%",
                  fontWeight: "bold",
                }}
              >
                Artist
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSongs.map((song, index) => (
              <TableRow
                key={song.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {song.name}
                </TableCell>
                <TableCell>{song.artist}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Songs;
