import BookIcon from "@mui/icons-material/Book";
import FestivalIcon from "@mui/icons-material/Festival";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css";

function HomePageSmall() {
  const dancestyle_images = `${process.env.REACT_APP_AWS_S3_BUCKET_URL}/dancestyle_images`;

  return (
    <Box
      sx={{
        // backgroundImage: `url("${dancestyle_images}/home-page-wallpaper-aachener-weiher-mirrored.jpg")`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h1"
          sx={{
            color: "white",
            textShadow: "1px 1px 10px black",
            fontSize: { xs: "2rem", md: "3rem" },
            padding: "4px 8px",
          }}
        >
          Explore DanceMaps
        </Typography>

        <Box // Replace Grid with Box for vertical arrangement
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2, // Add gap between cards
            mb: 2, // Add margin top
          }}
        >
          {/* Card 1  */}
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={`${dancestyle_images}/home-page-festivals.jpeg`}
              alt="Festivals"
            />
            <CardContent>
              <Button
                component={Link}
                to="/festival"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<FestivalIcon />}
                sx={{ py: 2 }}
              >
                Festivals
              </Button>
              <Typography
                variant="body2"
                align="center"
                mt={1}
                sx={{
                  fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                  color: "white",
                  textShadow: "1px 1px 2px black",
                  padding: "4px 8px",
                }}
              >
                Discover upcoming dance festivals around the world.
              </Typography>
            </CardContent>
          </Card>
          {/* Card 2 */}
          <Card sx={{ boxShadow: 6 }}>
            <CardMedia
              component="img"
              height="140"
              image={`${dancestyle_images}/home-page-local-parties.jpeg`}
              alt="Local Parties"
            />
            <CardContent>
              <Button
                component={Link}
                to="/local_party"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<LocationOnIcon />}
                sx={{ py: 2 }}
              >
                Local Parties
              </Button>
              <Typography
                variant="body2"
                align="center"
                mt={1}
                sx={{
                  fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                  color: "white",
                  textShadow: "1px 1px 2px black",
                  padding: "4px 8px",
                }}
              >
                Find local dance parties and events near you.
              </Typography>
            </CardContent>
          </Card>
          {/* Card 3 */}
          <Card sx={{ boxShadow: 6 }}>
            <CardMedia
              component="img"
              height="140"
              image={`${dancestyle_images}/home-page-dictionary.jpeg`}
              alt="Dictionary"
            />
            <CardContent>
              <Button
                component={Link}
                to="/dance_dictionary"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<BookIcon />}
                sx={{ py: 2 }}
              >
                Dictionary
              </Button>
              <Typography
                variant="body2"
                align="center"
                mt={1}
                sx={{
                  fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                  color: "white",
                  textShadow: "1px 1px 2px black",
                  padding: "4px 8px",
                }}
              >
                Explore a dance dictionary with figures and terminology.
              </Typography>
            </CardContent>
          </Card>
          {/* Card 4 */}
          <Card sx={{ boxShadow: 6 }}>
            <CardMedia
              component="img"
              height="140"
              image={`${dancestyle_images}/home-page-songs-charts.jpeg`}
              alt="Songs Charts"
              sx={{ objectFit: "cover", position: "0px 0px" }}
            />
            <CardContent>
              <Button
                component={Link}
                to="/songs_charts"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<QueueMusicIcon />}
                sx={{ py: 2 }}
              >
                Songs Charts
              </Button>
              <Typography
                variant="body2"
                align="center"
                mt={1}
                sx={{
                  fontSize: { xs: "0.8rem", md: "1rem", lg: "1.2rem" },
                  color: "white",
                  textShadow: "1px 1px 2px black",
                  padding: "4px 8px",
                }}
              >
                Browse the latest and most popular dance songs.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default HomePageSmall;
