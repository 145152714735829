import { Facebook, Instagram } from "@mui/icons-material";
import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./Footer.module.css";

function Footer() {
  const location = useLocation();

  // Hide elements on certain pages
  const hideElements =
    location.pathname === "/local_party" || location.pathname === "/festival";

  if (hideElements) {
    return null;
  }

  return (
    <Box className={styles.footer} component="footer">
      <>
        <Stack direction="row">
          <Typography variant="body2" className={styles.copyright}>
            &copy; {new Date().getFullYear()} DanceMaps. All rights reserved.
          </Typography>
        </Stack>
        <>
          <Stack direction="row" className={styles["legal-links"]}>
            <Link href="/privacy-policy" color="inherit" underline="hover">
              Privacy
            </Link>
            <Link href="/terms-of-service" color="inherit" underline="hover">
              Terms
            </Link>
            <Link href="/disclaimer" color="inherit" underline="hover">
              Disclaimer
            </Link>
            {/* add Impressum */}
            <Link href="/impressum" color="inherit" underline="hover">
              Impressum
            </Link>
          </Stack>
          <Stack direction="row" spacing={1} className={styles["social-media"]}>
            <IconButton
              color="inherit"
              aria-label="Facebook"
              href="https://www.facebook.com/profile.php?id=61560910366260"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Instagram"
              href="https://instagram.com/dancemaps_official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </IconButton>
          </Stack>
        </>
      </>
    </Box>
  );
}

export default Footer;
