import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { AddLocationAlt } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkIcon from "@mui/icons-material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapIcon from "@mui/icons-material/Map";
import ViewListIcon from "@mui/icons-material/ViewList";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import "dayjs/locale/de";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { useFavoritesStore } from "../../store/favoritesStore";
import { usePartyStore } from "../../store/localPartyStore.js";
import AddPartyForm from "./AddPartyForm";
import markerIconPng from "./marker-icon.png";
import PartyFavoriteButton from "./PartyFavoriteButton";

const RecenterMap = () => {
  const map = useMap();
  const latitude = usePartyStore((state) => state.latitude);
  const longitude = usePartyStore((state) => state.longitude);

  useEffect(() => {
    if (latitude && longitude) {
      map.panTo([latitude, longitude]);
    }
  }, [latitude, longitude, map]);

  return null;
};

const LocalParty = () => {
  const [parties, setParties] = useState([]);
  const [partyTypeFilter, setPartyTypeFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState("");
  const [showForm, setShowForm] = useState(false);
  const markerPosition = usePartyStore((state) => state.markerPosition);
  const latitude = usePartyStore((state) => state.latitude);
  const longitude = usePartyStore((state) => state.longitude);
  const setLatitude = usePartyStore((state) => state.setLatitude);
  const setLongitude = usePartyStore((state) => state.setLongitude);
  const snackbarMessage = usePartyStore((state) => state.snackbarMessage);
  const snackbarSeverity = usePartyStore((state) => state.snackbarSeverity);
  const snackbarOpen = usePartyStore((state) => state.snackbarOpen);
  const setSnackbarOpen = usePartyStore((state) => state.setSnackbarOpen);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const favorites = useFavoritesStore((state) => state.favorites);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [viewMode, setViewMode] = useState("map"); // 'map' or 'list'

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
    // Notify the parent component about the change
  };

  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });

  const partyTypeOptions = [
    "Bachata",
    "Kizomba",
    "Salsa",
    "West Coast Swing",
    "Zouk",
  ];

  const fetchParties = async (bounds) => {
    if (!bounds) {
      bounds = L.latLngBounds(
        L.latLng(50.904656637181766, 6.890487670898438),
        L.latLng(50.98231659641195, 7.016315460205079)
      );
    }

    let url = `${process.env.REACT_APP_API_URL}/parties/`;
    url += `?southWestLatitude=${bounds._southWest.lat}&southWestLongitude=${bounds._southWest.lng}&northEastLatitude=${bounds._northEast.lat}&northEastLongitude=${bounds._northEast.lng}`;

    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setParties(data);
      });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          const bounds = L.latLngBounds(
            L.latLng(
              position.coords.latitude - 0.1,
              position.coords.longitude - 0.1
            ),
            L.latLng(
              position.coords.latitude + 0.1,
              position.coords.longitude + 0.1
            )
          );
          fetchParties(bounds);
        },
        (error) => {
          fetchParties();
          console.error("Error getting location:", error);
        }
      );
    } else {
      fetchParties(); // Call fetchParties with default bounds if no geolocation
    }
  }, []);

  const handleMapBoundsChanged = (bounds) => {
    fetchParties(bounds);
  };

  const debouncedHandleMapBoundsChanged = debounce(handleMapBoundsChanged, 500);

  const MapEventsComponent = () => {
    const map = useMapEvents({
      zoomend: () => {
        const bounds = map.getBounds();
        debouncedHandleMapBoundsChanged(bounds);
      },
    });
    return null;
  };

  const filteredParties = parties.filter((party) => {
    const isFavorite = favorites["localParties"].includes(party.id);
    if (showFavoritesOnly) {
      return isFavorite;
    }

    if (partyTypeFilter.length === 0 && !dateFilter && !showFavoritesOnly) {
      return true; // Show all if no filters are active
    }

    const typeMatch =
      partyTypeFilter.length === 0 ||
      partyTypeFilter.some((filterType) => party.type.includes(filterType));
    const dateMatch = !dateFilter || party.date === dateFilter;
    return typeMatch && dateMatch;
  });

  const disabledDates =
    partyTypeFilter.length === 0
      ? parties.map((party) => party.date) // Use all parties if no type filter
      : filteredParties.map((party) => party.date);

  const handlePartyTypeFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setPartyTypeFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleDateFilterChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      // Check if newValue is valid
      setDateFilter(newValue.format("YYYY-MM-DD")); // Only update if valid
    } else {
      setDateFilter(""); // Clear the date if invalid or null
    }
  };

  // Group parties by type and location
  const groupedParties = filteredParties.reduce((acc, party) => {
    const key = `${party.type}-${party.latitude}-${party.longitude}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(party);
    return acc;
  }, {});

  // Get the soonest party from each group
  const soonestParties = Object.values(groupedParties).map((group) => {
    return group.sort((a, b) => new Date(a.date) - new Date(b.date))[0];
  });

  return (
    <>
      {isSmScreen && (
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "4px",
            width: "100%",
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {showFavoritesOnly ? (
            <FavoriteIcon
              onClick={handleFavoriteToggle}
              style={{ cursor: "pointer" }}
              sx={{
                color: "red",
                margin: 1,
              }}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={handleFavoriteToggle}
              style={{ cursor: "pointer" }}
              sx={{
                color: "black",
                margin: 1,
              }}
            />
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <MobileDatePicker
              label="Date"
              value={dateFilter ? dayjs(dateFilter) : null}
              closeOnSelect={true}
              onChange={(newValue) => {
                if (newValue === null) {
                  setDateFilter("");
                } else {
                  handleDateFilterChange(newValue);
                }
              }}
              shouldDisableDate={(date) => {
                return (
                  date < dayjs().subtract(1, "day") ||
                  !disabledDates.includes(date.format("YYYY-MM-DD"))
                );
              }}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
                textField: { size: "small" },
              }}
            />
          </LocalizationProvider>

          <FormControl size="small">
            <Select
              displayEmpty
              labelId="party-type-filter-label"
              id="party-type-filter"
              multiple
              value={partyTypeFilter}
              input={<OutlinedInput label="Party Type" />}
              renderValue={() => null}
              onChange={handlePartyTypeFilterChange}
            >
              {partyTypeOptions.map((typeOption) => (
                <MenuItem key={typeOption} value={typeOption}>
                  <Checkbox
                    checked={partyTypeFilter.indexOf(typeOption) > -1}
                  />
                  <ListItemText primary={typeOption} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={(event, newViewMode) => setViewMode(newViewMode)}
            aria-label="View mode"
            sx={{ margin: 1 }}
          >
            <ToggleButton value="map" aria-label="map view">
              <MapIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      <MapContainer
        center={[latitude, longitude]}
        zoom={12}
        style={{ height: isSmScreen ? "82vh" : "92vh" }}
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        <MarkerClusterGroup
          key={soonestParties.map((party) => party.id).join(",")}
          showCoverageOnHover={false}
        >
          {soonestParties.map((party) => (
            <Marker
              key={party.id}
              position={[party.latitude, party.longitude]}
              icon={customIcon}
            >
              <Popup closeOnClick={false} autoClose={false}>
                <Card sx={{ maxWidth: 200, position: "relative" }}>
                  <Box
                    sx={{ position: "relative", top: 8, right: -5, zIndex: 1 }}
                  >
                    <PartyFavoriteButton party={party} />
                  </Box>
                  <CardContent sx={{ padding: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: 16,
                        marginBottom: 1,
                        maxWidth: 200,
                      }}
                    >
                      {party.name}
                    </Typography>
                    {party.organizer && (
                      <>
                        <Typography
                          variant="h3"
                          color="text.secondary"
                          sx={{ fontSize: 12, marginBottom: 0.25 }}
                        >
                          Organizer: {party.organizer}
                        </Typography>
                        <Divider sx={{ my: 0.5 }} />
                      </>
                    )}
                    <Typography
                      variant="h3"
                      color="text.secondary"
                      sx={{ fontSize: 12, marginBottom: 0.25 }}
                    >
                      {party.type}
                    </Typography>
                    <Divider sx={{ my: 0.5 }} />
                    <Typography
                      variant="h3"
                      color="text.secondary"
                      sx={{ fontSize: 12 }}
                    >
                      {party.date}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ padding: 1 }}>
                    {party.link && (
                      <IconButton
                        size="small"
                        href={party.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ fontSize: 12 }}
                      >
                        {party.link.includes("facebook") ? (
                          <FacebookIcon />
                        ) : party.link.includes("instagram") ? (
                          <InstagramIcon />
                        ) : (
                          <LinkIcon />
                        )}
                      </IconButton>
                    )}
                    {party.address && (
                      <IconButton
                        size="small"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          party.address
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ fontSize: 12 }}
                      >
                        <LocationOnIcon />
                      </IconButton>
                    )}
                  </CardActions>
                </Card>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
        {!isSmScreen && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "1rem 1rem 0.5rem 1rem",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="party-type-filter-label">Filter</InputLabel>
                <Select
                  labelId="party-type-filter-label"
                  id="party-type-filter"
                  multiple
                  value={partyTypeFilter}
                  input={<OutlinedInput label="Party Type" />}
                  renderValue={(selected) =>
                    selected.length > 0 ? selected.join(", ") : "All"
                  }
                  onChange={handlePartyTypeFilterChange}
                >
                  {partyTypeOptions.map((typeOption) => (
                    <MenuItem key={typeOption} value={typeOption}>
                      <Checkbox
                        checked={partyTypeFilter.indexOf(typeOption) > -1}
                      />
                      <ListItemText primary={typeOption} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <DesktopDatePicker
                  label="Date Filter"
                  value={dateFilter ? dayjs(dateFilter) : null}
                  closeOnSelect={true}
                  onChange={(newValue) => {
                    if (newValue === null) {
                      setDateFilter("");
                    } else {
                      handleDateFilterChange(newValue);
                    }
                  }}
                  shouldDisableDate={(date) => {
                    return (
                      date < dayjs().subtract(1, "day") ||
                      !disabledDates.includes(date.format("YYYY-MM-DD"))
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  slotProps={{ field: { clearable: true } }}
                  sx={{ width: 180 }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={showFavoritesOnly}
                    onChange={handleFavoriteToggle}
                    name="showFavoritesOnly"
                    color="primary"
                  />
                }
                label="Favorites Only"
              />
            </Box>
          </Box>
        )}
        {isSmScreen ? ( // Conditionally render the button
          <IconButton
            sx={{
              position: "absolute",
              bottom: 50,
              left: 10,
              zIndex: 1000,
            }}
            variant="contained"
            onClick={() => setShowForm(true)}
          >
            {/* You can add an icon here if needed */}
            <AddLocationAlt />
          </IconButton>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              zIndex: 1000,
              padding: "1rem",
            }}
          >
            <Button variant="contained" onClick={() => setShowForm(true)}>
              Add Your Party
            </Button>
          </div>
        )}
        {showForm && <AddPartyForm onClose={() => setShowForm(false)} />}
        {markerPosition && (
          <Marker
            position={markerPosition}
            icon={
              new L.icon({
                iconUrl: markerIconPng,
                iconSize: ["auto", 40],
                iconAnchor: [12, 40],
              })
            }
          >
            {/* You can add a popup here if needed */}
            <Popup>Party Location</Popup>
          </Marker>
        )}
        <RecenterMap />
        <MapEventsComponent />
      </MapContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LocalParty;
